.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  color: var(--white);
}

.markdown a {
  color: #e0e0e0; /* 明るいグレーを使用 */
  text-decoration: none; /* 下線を消す */
  font-weight: bold; /* 太文字で強調 */
  transition: color 0.3s ease, text-shadow 0.3s ease; /* 色と影の変化をスムーズに */
}

.markdown a:hover {
  color: var(--white); /* ホバー時に色を白に変化 */
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.6); /* ホバー時に輝くような視覚効果 */
  text-decoration: underline; /* ホバー時に下線を表示 */
}

.markdown a:focus {
  outline: none; /* フォーカス時のアウトラインを消す */
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.8); /* フォーカス時の強調 */
}

.markdown img {
  border: 4px solid rgba(255, 255, 255, 0.6); /* 境界線を少し太くし、透明度を調整 */
  border-radius: 12px; /* 角をさらに丸くする */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); /* 影を強調して立体感を出す */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* 変化をスムーズにする */
}

.markdown img:hover {
  transform: scale(1.05); /* ホバー時に画像を拡大して視覚的に強調 */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.8); /* ホバー時の影を強調 */
}

video {
  border: 2px solid rgba(255, 255, 255, 0.8); /* 白色を薄く */
  border-radius: 12px; /* 角をさらに丸く */
  max-width: 100%; /* 親要素の幅に合わせて縮小 */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5); /* 影を強調 */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* 変化をスムーズに */
}

video:hover {
  transform: scale(1.03); /* ホバー時に少し拡大 */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7); /* ホバー時の影を強調 */
}

video:focus {
  outline: none; /* フォーカス時のアウトラインを消す */
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5); /* フォーカス時の強調 */
}

p code {
  color: #f0f0f0; /* 明るいグレーに変更 */
  background-color: #2b2b2b; /* ダークグレーの背景を設定 */
  padding: 4px 8px; /* 内側の余白を追加 */
  border-radius: 4px; /* 角を少し丸める */
  font-weight: bold; /* 太文字に設定 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 軽い影を追加 */
  font-family: 'Fira Code', monospace; /* スタイリッシュな等幅フォントを使用 */
  transition: background-color 0.3s ease; /* 背景色の変化をスムーズにする */
}

p code:hover {
  background-color: #44475a; /* ホバー時に背景色を変化させる */
}

pre code {
  display: block; /* ブロック要素として表示 */
  padding: 16px; /* 内側の余白を設定 */
  background-color: #2d2d2d; /* 黒に近いグレーの背景色を設定 */
  color: #f8f8f2; /* 明るいテキスト色を設定 */
  font-family: 'Courier New', Courier, monospace; /* 等幅フォントを使用 */
  font-size: 14px; /* フォントサイズを設定 */
  line-height: 1.5; /* 行間を設定 */
  border-radius: 8px; /* 角を丸くする */
  overflow-x: auto; /* 横に長いコードブロックに対してスクロール可能にする */
  border: 1px solid #444; /* 境界線を薄めのグレーに設定 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* 影を追加して立体感を出す */
}
